import React, { useRef, useState } from 'react';
import ReactSlider from 'react-slick';
import ModalVideo from 'react-modal-video';
import PlayIcon from './PlayIcon';
import Arrow from './Arrow';
import {
  StyledContainer,
  Kicker,
  Heading,
  Subhead,
  Container,
  HeadingContainer,
  WatchVideoContainer,
  InnerSlide,
} from './ExpressVideoCarousel.styled';
import { getYoutubeId } from '../../../utils/getYoutubeId';

export default function ExpressVideoCarousel({
  slides,
  kicker,
  heading,
  description,
  callToAction,
}) {
  let sliderRef = useRef(null);
  const [play, setPlay] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = {
    dots: true,
    arrows: false,
    centerMode: true,
    draggable: false,
    useTransform: true,
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    initialSlide: 0,
    speed: 500,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots',
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          draggable: true,
        },
      },
    ],
  };

  const next = () => {
    sliderRef.slickNext();
  };

  const previous = () => {
    sliderRef.slickPrev();
  };

  return (
    <Container>
      <HeadingContainer>
        <div className="center-content">
          {kicker && <Kicker>{kicker}</Kicker>}
          <Heading style={{ whiteSpace: 'pre-line' }}>
            {heading.replace('\\n', '\n')}
          </Heading>
          {description && <Subhead>{description}</Subhead>}
          {callToAction && callToAction.youtubeUrl && (
            <WatchVideoContainer onClick={() => setPlay(true)}>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.16666 3L15.8333 10.5L4.16666 18V3Z"
                  stroke="#343643"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span>See it in Action</span>
            </WatchVideoContainer>
          )}
        </div>
      </HeadingContainer>
      <StyledContainer className="slider-container">
        <button className="left-arrow" onClick={previous}>
          <Arrow />
        </button>
        <ReactSlider
          {...settings}
          ref={slider => {
            sliderRef = slider;
          }}
          afterChange={index => {
            setActiveIndex(index);
          }}
          centerPadding="0px"
        >
          {slides.map((slide, key) => (
            <div key={`evc_${key}`}>
              <InnerSlide
                className="inner-slide"
                isLink={!!slide.url}
                onClick={() => {
                  if (slide.url) setPlay(true);
                }}
              >
                {slide.url && <PlayIcon />}
                <img
                  src={slide?.backgroundImage?.url}
                  alt={slide?.backgroundImage?.internalName || ''}
                />
                <span>{slide.heading}</span>
              </InnerSlide>
            </div>
          ))}
        </ReactSlider>
        <button className="right-arrow" onClick={next}>
          <Arrow />
        </button>
      </StyledContainer>
      {typeof window !== 'undefined' && (
        <ModalVideo
          channel="youtube"
          isOpen={play}
          videoId={getYoutubeId(
            callToAction?.youtubeUrl
              ? callToAction.youtubeUrl
              : slides[activeIndex]?.url
          )}
          onClose={() => setPlay(false)}
        />
      )}
    </Container>
  );
}
