import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { font } from '../../../styles/atoms/typography';

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .center-content {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding-top: 72px;
  padding-bottom: 72px;
`;

export const Kicker = styled.p`
  ${font('overline', 'md', '700')}
  color: ${colors.primary[500]};
`;

export const Heading = styled.div`
  ${font('display', 'md', '700')}
  color: ${props => props.color};
  text-align: center;
  width: 90vw;

  ${atMinWidth.sm`
    ${font('display', 'md', '700')}
    width: 90vw;
  `}

  ${atMinWidth.md`
    ${font('display', 'lg', '700')}
  `}

  ${atMinWidth.lg`
    ${font('display', 'xxl', '700')}
  `}

  ${atMinWidth.xl`
    ${font('display', 'xxl', '700')}
  `}
`;

export const Subhead = styled.div`
  color: ${props => props.color};
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  ${font('text', 'md', '400')}
  justify-content: inherit;

  color: var(--Gray-600, #6f7283);
  text-align: center;
  /* Text xl/Regular */
  font-family: CircularXX;
  font-size: 22.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33.75px */
  letter-spacing: -0.27px;
`;

export const StyledContainer = styled.div`
  padding-bottom: 50px;
  position: relative;

  .right-arrow {
  
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 22%;
    top: calc(45% - 25px);
    z-index: 9999999999;
    background-color: transparent;
    border: none;
    display: none;
    ${atMinWidth.sm`
      display: none;
    `}
    ${atMinWidth.md`
      display: flex;
      right: 2%;
    `}
    ${atMinWidth.lg`
      right: 16%;
    `}
    ${atMinWidth.xl`
      right: 16%;
    `}
  }
  .right-arrow:active {
    opacity: 0.5;
  }
  .left-arrow {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 22%;
    top: calc(45% - 25px);
    z-index: 9999999999;
    rotate: 180deg;
    background-color: transparent;
    border: none;
     display: none;
     ${atMinWidth.sm`
      display: none;
    `}
    ${atMinWidth.md`
      display: flex;
      left: 2%;
    `}
    ${atMinWidth.lg`
      left: 16%;
    `}
     ${atMinWidth.xl`
      left: 16%;
    `}
  }
  
  .left-arrow:active {
    opacity: 0.5;
  }
  .slick-list {
    margin: 0 0;
    height: 430px;

    ${atMinWidth.md`
      height: 540px ;
    `}
    ${atMinWidth.lg`
      height: 620px;
    `}
  }
  .slick-slide {
    transition: all 0.5s ease-in-out;
    margin: 0 0;
    > div {
      // automatically generated 'div' by 'react-slick'
      margin-inline: -15px; //  space between slides / 2
    }

    .inner-slide {
      position: relative;
      cursor: ${props => (props.isLink ? 'pointer' : 'default')};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      width: calc(425.35px - 40px);
      height: 450px;

      ${atMinWidth.md`
        width: 700px;
        height: 540px;
      `}
      ${atMinWidth.lg`
        width: 768px;
        height: 620px;
      `}
      gap: 32px;
      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        width: calc(100% - 40px);
        height: auto;

      ${atMinWidth.md`
        width: 700px;
        height: 484px;
      `}
      ${atMinWidth.lg`
        width: 768px;
        height: 530px;
      `}
        
      }
    }
    span {
      color: #000;
      text-align: center;
      font-family: CircularXX;
      font-size: 22.5px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 33.75px */
      letter-spacing: -0.27px;
      width: 85%;
      ${atMinWidth.md`
        width: 100%;
      `}
    }
    &:not(.slick-active) {
      opacity: 0.4;
      filter: blur(10px);
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
      transform: scale(0.8);
      span {
        opacity: 0;
      }
    }
    &.slick-active {
      opacity: 1;
      transform: scale(1);
      span {
        opacity: 1;
      }
    }
  }
  .slick-dots {
    bottom: -40px;
    li {
      width: 10px;
      height: 10px;
      margin: 0 0;
      padding: 0 8px 0 8px;
      button {
        width: 10px;
        height: 10px;
        padding: 0;
      }
    }
    li button:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${colors.gray[400]};
      content: '';
    }
    li.slick-active button:before {
      background-color: ${colors.primary[500]} !important;
    }
  }
`;

export const InnerSlide = styled.div`
  position: relative;
  cursor: ${props => (props.isLink ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 425.35px;
  height: 450px;

  ${atMinWidth.md`
    width: 700px;
    height: 484px;
  `}
  ${atMinWidth.lg`
    width: 768px;
    height: 530px;
  `}

  ${atMinWidth.md`
    width: auto;
    height: auto
  `}
  gap: 32px;
  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    width: 425.35px;
    height: 294.099px;

  ${atMinWidth.md`
    width: 700px;
    height: 484px;
  `}
  ${atMinWidth.lg`
    width: 768px;
    height: 530px;
  `}
  }
`;

export const WatchVideoContainer = styled.button`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 211px;
  height: 51px;
  border-radius: var(--Border-Radius-rounded-lg, 8px);
  border: 1px solid var(--Color-Gray-gray-300, ${colors.gray[300]});
  background: var(--Color-Gray-white, ${colors.base.white});
  padding: 12px 6px;
  span {
    color: var(--Gray-800, var(--Color-Gray-gray-800, ${colors.gray[800]}));
    font-family: CircularXX;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    letter-spacing: -0.27px;
  }
`;
